

/*
Removes authenticated user in active session
*/

import React, { useEffect } from "react"
import Layout from "../components/layout";
import { navigate } from "gatsby";

import loginSession from "../../lib/class/loginSession";


const Logout = () => {
	useEffect(() => {
		var authentication = new loginSession();
		authentication
			.signOut()
			.then(response => {
				navigate("/");
			})
			.catch(err => {
				navigate("/");
			});
	}, [])
	return <Layout activePath="logout" pageTitle="Logout" privatePage={true}>
		<p class="bannertext font-size-large">Signing out...</p>
	</Layout>
}

export default Logout;